@font-face {
    font-family: 'Geometria';
    src: url('../assets/fonts/Geometria.eot');
    src: url('../assets/fonts/Geometria.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Geometria.woff') format('woff'),
    url('../assets/fonts/Geometria.ttf') format('truetype') {}
    font-weight: normal;
    font-style: normal; }

@font-face {
    font-family: 'Geometria';
    src: url('../assets/fonts/Geometria-Medium.eot');
    src: url('../assets/fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Geometria-Medium.woff') format('woff'),
    url('../assets/fonts/Geometria-Medium.ttf') format('truetype') {}
    font-weight: bold;
    font-style: normal; }
